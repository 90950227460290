import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { useViewport } from "../../../shared/hooks";

import { desktopPadding } from "..";
import Typography from "../../../shared/components/Typography/Typography";

import Email from "../../../shared/icons/Email";
import Person from "../../../shared/icons/Person";
import CheckList from "../../../shared/icons/Checklist";

import {
  contactItem,
  contactItemSubtitle,
  contactItemTitle,
} from "./ContactSection.styles";

// @ts-ignore
const ContactItem = styled(Grid)(contactItem);
const ContactItemTitle = styled(Typography)(contactItemTitle);
const ContactItemSubtitle = styled(Typography)(contactItemSubtitle);

export const ContactSection = () => {
  const matchesXS = useViewport(600).below;
  const matchesXL = useViewport(1200).below;

  const contactSectionItems = [
    {
      id: 1,
      icon: <CheckList />,
      title: "Buy A Plan",
      subTitle: "",
      actionComponent: (
        <a href={"tel:1800-203-5314"}>
          <Typography
            variant="subtitle2"
            style={{ textDecoration: "underline", fontWeight: "500" }}
          >
            1800-203-5314
          </Typography>
        </a>
      ),
    },
    {
      id: 2,
      icon: <Email />,
      title: "Email Us",
      subTitle: "",
      actionComponent: (
        <a href="mailto:care@kenko-health.in">
          <Typography
            variant="subtitle2"
            style={{ textDecoration: "underline", fontWeight: "500" }}
          >
            care@kenko-health.in
          </Typography>
        </a>
      ),
    },
    {
      id: 3,
      icon: <Person />,
      title: "Emergency",
      subTitle: "",
      actionComponent: (
        <a href={"tel:+919113839585"}>
          <Typography
            variant="subtitle2"
            style={{ textDecoration: "underline", fontWeight: "500" }}
          >
            +91 9113839585
          </Typography>
        </a>
      ),
    },
    // {
    //   id: 4,
    //   icon: <CustomerSupport />,
    //   title: "Chat With Akira",
    //   subTitle: (
    //     <span>
    //       <b>Akira</b>, our customer support is always available to help.
    //     </span>
    //   ),
    //   actionComponent: (
    //     <Button
    //       label={matchesXS ? "Akira, For Your Support" : "Help me, Akira!"}
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         width: matchesXS ? "250px" : "180px",
    //         boxShadow: "unset",
    //         height: "33px",
    //         borderRadius: "5px",
    //         fontSize: "12px",
    //         margin: matchesXS ? "auto" : "initial",
    //       }}
    //       onClick={() => {
    //         window?.$zopim?.livechat.window.show();
    //       }}
    //     />
    //   ),
    // },
  ];

  return (
    <Grid
      container
      // spacing={8}
      justifyContent={matchesXL ? "space-around" : "center"}
      style={{
        padding: matchesXS ? 0 : `10px ${desktopPadding}`,
        margin: 0,
        width: matchesXS ? "100vw" : "auto",
      }}
    >
      {contactSectionItems.map((item) => (
        <ContactItem
          item
          xs={12}
          lg={3}
          key={item.id}
          style={{
            textAlign: matchesXS ? "center" : undefined,
            borderTop: matchesXS ? "4px solid #ffffff" : 0,
          }}
        >
          <Grid container spacing={matchesXS ? 2 : 4} style={{ margin: 0 }}>
            <Grid
              item
              style={{
                padding: "0 12px",
                margin: "auto",
              }}
            >
              {item.icon}
            </Grid>
            <Grid item xs={12} style={{ padding: "16px 12px 10px" }}>
              <ContactItemTitle variant="h6">{item.title}</ContactItemTitle>
              <ContactItemSubtitle variant="body3">
                {item.subTitle}
              </ContactItemSubtitle>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                padding: "0 12px",
              }}
            >
              {item.actionComponent}
            </Grid>
          </Grid>
        </ContactItem>
      ))}
    </Grid>
  );
};

export default ContactSection;
